<template>
  <el-dialog
    title="禁售品类"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="50%"
    class="dialog"
    center
  >
    <div class="boxWrap" v-loading="dialogLoading">
      <el-tree
        ref="trees"
        :data="data"
        show-checkbox
        node-key="id"
        :props="defaultProps"
        :default-checked-keys="checkKeys"
        @check-change="handleCheckChange"
        @check="handleCheck"
      >
      </el-tree>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onHandleSubmit"
        >确 认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  postLogisticsForbidCategories,
  postLogisticsForbidCategoriesSave,
} from "@/api/general/city-store.js";
export default {
  name: "ProductSupplementDialog",
  props: {},
  data() {
    return {
      data: [],
      checkKeys: [],
      checkedIds: [],
      selectedNodes: [], // 存储选中的节点
      defaultProps: {
        children: "child",
        label: "name",
      },
      dialogVisible: false,
      submitLoading: false, // 提交按钮禁用
      id: "",
      dialogLoading: false,
    };
  },
  methods: {
    // 当节点选择或取消选择时触发
    handleCheckChange(e, el, els) {
      let tmpList = this.$refs.trees.getCheckedNodes(false, true);
      let checkMenus = [];
      tmpList.forEach((item) => {
        checkMenus.push(item.id);
      });
      this.checkedIds = checkMenus;
      console.log(tmpList, this.checkedIds, this.checkKeys, "333");
    },
    // 当选择操作完成后触发
    handleCheck(checkedNodes) {
      console.log("Check:", checkedNodes);
    },
    /**
     * 初始化
     */
    onInitData(data) {
      console.log(data, "data");
      this.dialogVisible = true;
      this.id = data.id;
      this.data = [];
      this.checkKeys = [];
      this.checkedIds = [];
      this.dialogLoading = true;
      this.postAjaxLogisticsForbidCategories(data);
    },
    //  禁售品类
    async postAjaxLogisticsForbidCategories(e) {
      try {
        const res = await postLogisticsForbidCategories({
          id: e.id,
        });
        this.data = res.data || [];
        let tmpArr = [];
        this.data.map((oneItem) => {
          if (oneItem.is_selected == 1) {
            tmpArr.push(oneItem.id);
          }
          oneItem.child.map((twoItem) => {
            if (twoItem.is_selected == 1) {
              tmpArr.push(twoItem.id);
            }
          });
        });
        this.checkKeys = tmpArr;
        this.checkedIds = tmpArr;
        console.log(this.checkKeys, this.checkedIds, "33");
      } catch (error) {
        console.log(error, "postLogisticsForbidCategories");
      } finally {
        this.dialogLoading = false;
      }
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    async onHandleSubmit() {
      this.submitLoading = true;
      try {
        await postLogisticsForbidCategoriesSave({
          id: this.id,
          cid: this.checkedIds,
        });
        this.$message.success("禁售品类成功");
        this.onHandleClose();
        this.$emit("on-refresh-list");
      } catch (err) {
        console.log("ajax postLogisticsForbidCategoriesSave err", err);
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.boxWrap {
  padding: 30px;
}
</style>
