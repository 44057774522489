<template>
  <!-- 签约 -->
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="50%"
    class="dialog"
    center
  >
    <div class="boxWrap d-flex a-center">
      <div class="mr-15">签约状态:</div>
      <div>
        <el-radio v-model="radio" label="2">已签约</el-radio>
        <el-radio v-model="radio" label="0">未签约</el-radio>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onHandleSubmit"
        >确 认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { postLogisticsContractStatus } from "@/api/general/city-store";
export default {
  name: "sign-a-contract-dialog",
  props: {},
  data() {
    return {
      title: "",
      radio: "",
      data: [],
      dialogVisible: false,
      submitLoading: false, // 提交按钮禁用
      detail: {},
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      console.log(data, "data");
      this.detail = data;
      this.dialogVisible = true;
      this.title = data.name;
      this.radio = data.contract_status.toString();
    },
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.submitLoading = true;
      this.postAjaxLogisticsContractStatus();
    },
    /**
     * 签约接口请求
     */
    async postAjaxLogisticsContractStatus() {
      try {
        await postLogisticsContractStatus({
          id: this.detail.id,
          contract_status: this.radio,
        });
        this.$message.success("操作成功");
        this.onHandleClose();
        this.$emit("on-refresh-list");
      } catch (err) {
        console.log("ajax postLogisticsContractStatus err", err);
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.boxWrap {
  padding: 30px;
  justify-content: center;
}
</style>
